<template>
  <div class="app-container">
    <eHeader  :query="query" :bsysoptions="listBSysOptions"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="smsId" label="ID"/>
      <el-table-column prop="productName" label="产品名称"/>
      <el-table-column prop="contents" label="短信内容"/>
      <el-table-column prop="telephones" label="接收号码"/>
      <el-table-column prop="sendTime" label="发送时间">
      <template slot-scope="scope">
        <span>{{ parseTime(scope.row.sendTime) }}</span>
      </template>
      </el-table-column>
      <el-table-column prop="platformCode" label="短信服务平台编码"/>
      <el-table-column prop="sysCode" label="业务系统编码"/>
      <el-table-column prop="sign" label="签名"/>
      <el-table-column prop="resDesc" label="结果"/>
      <el-table-column prop="status" label="状态"/>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initSmsData'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/sms/smsinfo/header'
import { getSmsInfoList } from '@/api/sms'
export default {
  components: { eHeader },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this,formData:{},
      listBSysOptions: []
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = '/jrqsms/api/smsInfo/getSmsInfoList'
      const query = this.query
      const contents = query.contents
      const telephones = query.telephones
      this.formData = { currentPage: this.page, pageSize: this.size }
      if (contents !== '' && contents !== null) { this.formData['contents'] = contents }
      if (telephones !== '' && telephones !== null) { this.formData['telephones'] = telephones }
      this.params = JSON.stringify(this.formData)
      return true
    },
  }
}
</script>

<style scoped>

</style>
